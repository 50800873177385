import queryString from 'query-string'

import { env } from '@/env'

import { loadObject } from '../supabase/supabase-loader'

export const fileSrc = ({ bucket, src }: { bucket?: string; src?: string }) => {
  if (!bucket || !src) {
    return '#'
  }

  const newSrc = `${bucket}/${src}`

  return `${env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/${newSrc}`
}

export const imgSrc = ({
  bucket,
  src,
  width,
  quality,
}: {
  bucket: string
  src: string
  width?: number
  quality?: number
}) => {
  const newSrc = `${bucket}/${src}`
  return imageLoader({ src: newSrc, width, quality })
}

export default function imageLoader({
  src,
  width,
  height,
  quality = 80,
  fit = 'cover',
  format = 'auto',
  blur,
  trimTop,
  trimRight,
  trimBottom,
  trimLeft,
}: {
  src: string
  width?: number
  height?: number
  quality?: number
  fit?: 'cover' | 'crop' | 'contain' | 'scale-down'
  format?: string
  blur?: number
  trimTop?: number
  trimRight?: number
  trimBottom?: number
  trimLeft?: number
}) {
  if (env.NEXT_PUBLIC_ENV === 'development') {
    return loadObject(src)
  }

  return `https://imagedelivery.net/nWIHorj2sLU7QqDFjNRH8Q/${src}/quality=${quality},width=${width},format=${format},fit=${fit}${
    height ? `,height=${height}` : ''
  }${blur ? `,blur=${Math.min(blur, 250)}` : ''}${trimRight || trimBottom || trimLeft || trimTop ? `,trim=${trimTop || 0};${trimRight || 0};${trimBottom || 0};${trimLeft || 0}` : ''}`
}

export const fileLoader = ({
  src,
}: {
  src: string
  width: number
  quality?: number
}) => {
  return loadObject(src)
}

export const unsplashLoader = ({
  src,
  width = 500,
  height,
  quality = 80,
  blur,
  cropX,
  cropY,
  cropW,
  cropH,
  format,
}: {
  src: string
  width?: number
  height?: number
  quality?: number
  blur?: number
  cropX?: number
  cropY?: number
  cropW?: number
  cropH?: number
  format?: string
}) => {
  const x = cropX || 0
  const y = cropY || 0
  const w = cropW || 0
  const h = cropH || 0

  const queryParams = queryString.stringify({
    q: quality,
    w: width,
    h: height,
    blur: blur ? Math.min(blur, 2000) : undefined,
    rect: `${x},${y},${w},${h}`,
    fm: format || undefined,
  })

  return `${src}&${queryParams}`
}

export const previewImageLoader = ({
  src,
  width,
  height,
  quality = 80,
}: {
  src: string
  width: number
  height?: number
  quality?: number
}) => {
  return `https://prod-screenshot.fingertip.com/preview/${src}?width=${width}&quality=${quality}${
    height ? `,height=${height}&gravity=top` : ''
  }`
}

export const opengraphImageLoader = ({
  src,
  width,
  height,
  quality = 80,
}: {
  src: string
  width: number
  height?: number
  quality?: number
}) => {
  return `https://prod-screenshot.fingertip.com/opengraph/${src}?width=${width}&quality=${quality}${
    height ? `,height=${height}` : ''
  }`
}

export const themePreviewImageLoader = ({
  src,
  width,
  height,
  quality = 80,
}: {
  src: string
  width: number
  height?: number
  quality?: number
}) => {
  return `https://prod-screenshot.fingertip.com/theme-preview/${src}?width=${width}&quality=${quality}${
    height ? `,height=${height}` : ''
  }`
}
