import queryString from 'query-string'

import { env } from '@/env'

/**
 * Returns the render image URL of an image file in Supabase Storage
 * @param src
 * @param width
 * @param quality
 */
export const renderImage = ({
  src,
  width,
  quality = 75,
}: {
  src: string
  width?: number
  quality?: number
}) => {
  const queryParams = queryString.stringify({
    width,
    quality,
  })

  return `${env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/render/image/public/${src}?${queryParams}`
}

/**
 * Returns the URL of a file in Supabase Storage
 * @param src
 */
export const loadObject = (src: string) => {
  return `${env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/${src}`
}
